(function () {
    var stStr = '',
        $status,
        $type,
        jsonID = '/2023/assets/data/switch.json',
        errorTxt = '<p class="error">読み込みエラーです。<span class="ib">ブラウザの再読み込みをお試しください。</span></p>',
        query,
        sID;

    $.when(
        $.ajax({
            url: jsonID,
            dataType: 'json'
        })
    )
        .then(

            function (data) {
                if ($('body#streaming')[0]) {
                    $status = data.status;
                    if (location.href.indexOf('?') >= 0) {
                        query = location.href.split('?')[1];
                        sID = query;
                    } else {
                        sID = $status;
                    }

                    $.each(data.display, function () {
                        if (this.status === sID) {
                            $type = this.type;
                            stStr += '<div class="video__inner">';
                            if ($type === 'still') {
                                stStr += '<figure><img src="' + this.img[0].path + '" alt="' + this.img[0].alt + '"></figure>';
                            } else if ($type === 'video') {
                                stStr += '<iframe width="560" height="315" src="' + this.youtube_path + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                            }
                            stStr += '</div>';
                            stStr += '<div class="video__info">';

                            $.each(this.text, function () {
                                stStr += this
                            });
                            stStr += '</div>';

                            if (this.button.length > 0) {
                                stStr += '<div class="video__btn"><a href="' + this.button[0].link + '" target="' + this.button[0].target + '" rel="noopener">' + this.button[0].label + '</a></div>';
                            }
                        }

                    });


                    $('#loadStreaming').html(stStr);

                    Ts.loadFont();

                }
            },
            function () {
                $('#loadStreaming').html(errorTxt);
            }
        )
}());