//smoothscroll
jQuery.easing.quart = function(x, t, b, c, d) {
    'use strict';
    return -c * ((t = t / d - 1) * t * t * t - 1) + b;
};

let targetElement;

//ページトップへ
$('.pagetop a').click(function() {
    targetElement = document.querySelector('#column');;
    $('html, body').animate({
        scrollTop: 0
    }, 600, 'quart');
    targetElement.tabIndex = -1
    targetElement.focus();
    return false;
});