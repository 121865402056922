$(function () {
    var archiveStr = '',
        jsonID = '/2023/assets/data/archiiveslist.json';

    $.getJSON(jsonID, function (data) {
        $.each(data, function () {
            archiveStr += '<li class="archiveLinks__item"><a href="/' + this.year + '/"><span class="count">第<span class="num">' + this.count + '</span>回</span><span class="year">' + this.year + '</span></a></li>'
        });

        $('#loadArchiveList').html(archiveStr);

        Ts.loadFont();
    });

});