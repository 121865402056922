(function () {
    var galleryStr = '',
        $update,
        $name,
        $aID,
        $modalLink,
        $class = '',
        $lang = $('html').attr('lang'),
        newKeep = 5,
        y, m, d, diff, old_day, new_day,
        jsonID = '/2023/assets/data/gallery.json',
        errorTxt = '<p class="error">読み込みエラーです。<span class="ib">ブラウザの再読み込みをお試しください。</span></p>',
        query,
        qArray,
        gID;

    var sortBy = function (field, reverse, primer) {
        reverse = (reverse) ? -1 : 1;
        return function (a, b) {
            a = a[field];
            b = b[field];
            if (typeof (primer) !== 'undefined') {
                a = primer(a);
                b = primer(b);
            }
            if (a < b) {
                return reverse * -1;
            }
            if (a > b) {
                return reverse * 1;
            }
            return 0;
        };
    };



    $.when(
        $.ajax({
            url: jsonID,
            dataType: 'json'
        })
    )
        .then(

            function (data) {
                if ($('body#gallery')[0]) {
                    if (location.href.indexOf('?') >= 0) {
                        query = location.href.split('?')[1];
                        gID = query;
                    } else {
                        gID = 'hall';
                    }

                    galleryStr += '<div class="gallery">';

                    $.each(data, function () {
                        if (this.id === gID) {
                            $.each(this.list, function () {
                                $modalLink = '#modaalDialog';
                                $class = 'modaal';

                                galleryStr += '<div class="gallery__item"><a href="' + this.pic + '" class="' + $class + '" data-id="' + this.picID + '" data-group="gallery" data-modaal-desc="' + this.caption + '">';

                                galleryStr += '<figure class="gallery__fig"><img data-src="' + this.pic + '" src="/2023/assets/images/gallery/lazy.png" alt="' + this.caption + '" class="lazy" width="1280" height="853"></figure><div class="gallery__caption"><p class="caption">' + this.caption + '</p></div></a></div>';
                            });
                        }

                    });
                    galleryStr += '</div>'

                    $('#loadGallery').html(galleryStr);


                    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

                    if ("IntersectionObserver" in window) {
                        let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
                            entries.forEach(function (entry) {
                                if (entry.isIntersecting) {
                                    let lazyImage = entry.target;
                                    lazyImage.src = lazyImage.dataset.src;
                                    if (typeof lazyImage.dataset.srcset === "undefined") {
                                    } else {
                                        lazyImage.srcset = lazyImage.dataset.srcset;
                                    }
                                    lazyImage.classList.remove("lazy");
                                    lazyImageObserver.unobserve(lazyImage);
                                }
                            });
                        });

                        lazyImages.forEach(function (lazyImage) {
                            lazyImageObserver.observe(lazyImage);
                        });
                    } else {
                        // Possibly fall back to a more compatible method here
                    }

                    Ts.loadFont();

                    //モーダルウインドウ
                    $('.modaal').on('click', function () {
                    }).modaal({
                        type: 'image',
                        background_scroll: false,
                        accessible_title: '',
                        close_text: 'CLOSE',
                        close_aria_label: 'CLOSEボタンをクリックするとモーダルウインドウを閉じて元の画面に戻ります',
                        after_close: function () {
                            //
                        }
                    });
                }
            },
            function () {
                $('#loadGallery').html(errorTxt);
            }
        )
}());
