$(function () {
    var navStr = '',
        langStr = '',
        $lang = $('html').attr('lang'),
        $path = '',
        url = new URL(window.location.href),
        pathname = url.pathname,
        targetPath,
        jsonID = '/2023/assets/data/navigation.json';

    if (pathname.match(/en/)) {
        targetPath = pathname.replace('/en', '');
        $path = '/en';
    } else {
        targetPath = pathname;
    }
    $.getJSON(jsonID, function (data) {
        $.each(data, function () {
            $.each(this.language, function (cnt) {
                langStr += '<li class="selectLang__item"><a class="' + this.class + '" href="' + this.link + targetPath + '">' + this.label + '</a></li>'
            });
            $.each(this.globalNavigation, function () {
                if (this.target !== '_self') {
                    targetPath = pathname;
                    $path = '';
                } else {
                    if (pathname.match(/en/)) {
                        $path = '/en';
                    }
                }
                navStr += '<li class="navigation__item ' + this.class + '"><a href="' + $path + this.link + '" target="' + this.target + '">' + this.label + '</a></li>';
            });
        })

        $('#loadSelectLang').html(langStr);
        $('#loadNavigation').html(navStr);

        $("#gNavToggle").on("click", function () {
            $(this).toggleClass("js-active");
            $("#gNav").toggleClass("js-open");
            var waiaria = $(this).attr('aria-expanded');
            if (waiaria === 'false') {
                $(this).attr('aria-expanded', 'true');
            } else {
                $(this).attr('aria-expanded', 'false');
            }
        });

        posAdjust = $('.globalHeader').height();

        $('#gNav a').on('click', function () {
            $('#gNavToggle').removeClass("js-active")
            $("#gNav").removeClass("js-open");
            posAdjust = $('.globalHeader').height();
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var $target = $(this.hash),
                    targetElement = document.querySelector(event.currentTarget.hash),
                    targetOffset;
                $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
                if ($target.length) {
                    targetOffset = $target.offset().top - posAdjust;
                    $('html, body').animate({
                        scrollTop: targetOffset
                    }, 100, 'quart');
                    targetElement.tabIndex = -1
                    targetElement.focus();
                    return false;
                }
            }
        });

        var mediaQuery = window.matchMedia('(min-width: 1080px)');

        function handleTabletChange(e) {
            if (e.matches) {
                $('#gNavToggle').removeClass("js-active")
                $("#gNav").removeClass("js-open");
            }
        }
        // イベントリスナーを登録
        mediaQuery.addListener(handleTabletChange)

        // 初期チェック
        handleTabletChange(mediaQuery)

        Ts.loadFont();


        var options = {
            root: null, //nullでブラウザーのビューポートを対象にする
            rootMargin: "-20% 0% 0% 0%", //画面の下から-20%の位置をターゲットと交差する位置に指定
            threshold: 0.5, //指定した要素が画面に20%入るとコールバックイベント発生
        };

        var observer = new IntersectionObserver(callback, options); //引数callback、optionsを持ったobserverインスタンスを作成
        var target;

        if ($('body.toppage')[0]) {
            target = document.querySelector(".slider");
        } else {
            target = document.querySelector(".main");
        }

        // for (var i = 0; i < target.length; i++) {
        //     observer.observe(target[i]); //ターゲットごとに監視を開始する
        // }
        observer.observe(target); //ターゲットの監視を開始する
        // ブラウザ領域内に入ってきた時の処理
        function callback(entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    document.querySelector('.globalHeader').classList.remove('scrolled');
                    $('.snsLinks').fadeOut();
                } else {
                    document.querySelector('.globalHeader').classList.add('scrolled');
                    $('.snsLinks').fadeIn();
                }
            });
        };

    });

});