(function () {
    var sliderStr = '',
        $slider,
        $update,
        $eventDate,
        $program,
        $alt,
        $slideLength,
        $description,
        $thumbDesktop,
        $thumbMobile,
        $link,
        startSlide,
        $lang = $('html').attr('lang'),
        newKeep = 5,
        y, m, d, diff, old_day, new_day,
        jsonID = '/2023/assets/data/slider.json',
        errorTxt = '<p class="error">読み込みエラーです。<span class="ib">ブラウザの再読み込みをお試しください。</span></p>';

    $.when(
        $.ajax({
            url: jsonID,
            dataType: 'json'
        })
    )
        .then(
            function (data) {
                if ($('body.toppage')[0]) {
                    $slideLength = data.length;
                    startSlide = Math.floor(Math.random() * $slideLength);
                    $.each(data, function (count) {
                        if ($lang === 'ja') {
                            $eventDate = this.eventDateJa;
                            $program = this.programJa;
                            $alt = this.altJa;
                            $description = this.descriptionJa;
                            $thumbDesktop = this.thumbDesktopJa;
                            $thumbMobile = this.thumbMobileJa;
                            $link = this.linkJa;
                        }
                        sliderStr += '<div class="slider__item"><div class="slider__fig -cover">';
                        if ($link !== '') {
                            sliderStr += '<a href="' + $link + '" target="' + this.linktarget + '"><picture><source media="(min-width: 768px)" srcset="' + $thumbDesktop + '"><img src="' + $thumbMobile + '" alt="' + $alt + '" width="540" height="720"></picture></a>';
                        } else {
                            sliderStr += '<picture><source media="(min-width: 768px)" srcset="' + $thumbDesktop + '"><img src="' + $thumbMobile + '" alt="' + $alt + '" width="540" height="720"></picture>';
                        }
                        sliderStr += '</div></div>';

                    });
                    $('#loadSlider').html(sliderStr);

                    $('#loadSlider').slick({
                        // initialSlide: startSlide,
                        dots: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        speed: 500,
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 5000,
                        fade: true
                    });
                }

                Ts.loadFont();
            },
            function () {
                $('#loadSlider').html(errorTxt);
            }
        )
}());