(function () {
    var columnStr = '',
        sponsorStr = '',
        prBnrL_Str = '',
        prBnrM_Str = '',
        prBnrMharf_Str = '',
        prBnrS_Str = '',
        prBnrS40_Str = '',
        prBnrSS_Str = '',
        $date,
        $stream,
        $price,
        $organizer,
        $img,
        $imgSP,
        $text,
        $size,
        $link,
        $type,
        $term,
        $class,
        $lang = $('html').attr('lang'),
        jsonID = '/2023/assets/data/outline-sponsor.json',
        errorTxt = '<p class="error">読み込みエラーです。<span class="ib">ブラウザの再読み込みをお試しください。</span></p>';


    $.when(
        $.ajax({
            url: jsonID,
            dataType: 'json'
        })
    )
        .then(
            function (data) {
                if ($('body.toppage')[0]) {
                    columnStr += '<div class="column__inner">';
                    $.each(data, function () {
                        $type = this.type;
                        if ($lang === 'ja') {
                            $term = this.term;
                        }
                        columnStr += '<dl class="column__item clmn' + this.clmn + '">';
                        columnStr += '<dt class="column__term">' + $term + '</dt>';
                        columnStr += '<dd class="column__description">';
                        $.each(this.description, function () {
                            if ($lang === 'ja') {
                                $text = this.text;
                            }
                            if ($type === 'outline') {
                                columnStr += '<span class="block">' + $text + '</span>';
                            } else if ($type === 'sponsor') {

                                $.each(this.item, function () {
                                    if ($lang === 'ja') {
                                        $text = this.text;
                                    }
                                    columnStr += '<span class="block">' + $text + '</span>';
                                })
                            } else if ($type === 'bunka') {
                                columnStr += '<span class="flex items-center "><img src="' + this.img + '" alt="文化庁のロゴ" class="logo' + this.class + '"><span class="text' + this.class + '">' + $text + '</span></span>';
                            }

                        });
                        columnStr += '</dd>';
                        columnStr += '</dl>';

                        //スポンサーロゴエリア、広告エリア
                        if ($type === 'sponsor') {
                            sponsorStr += '<section class="sponsors__block"><h2 class="sponsors__ttl" lang="en">' + this.termEn + '</h2>';
                            $.each(this.description, function () {
                                $size = this.size;
                                sponsorStr += '<div class="sponsors__wrap -' + $size + '">';
                                $.each(this.item, function () {
                                    if ($lang === 'ja') {
                                        $text = this.text;
                                        $img = this.img;
                                        $link = this.link;
                                    }
                                    if ($link !== '') {
                                        sponsorStr += '<div class="sponsors__item ' + this.class + '"><a href="' + $link + '" target="_blank" rel="noopener" title="' + $text + 'のサイトを新しいウインドウで開きます"><img src="' + $img + '" alt="' + $text + '"></a></div>';
                                    } else {
                                        sponsorStr += '<div class="sponsors__item ' + this.class + '"><img src="' + $img + '" alt="' + $text + '"></div>';
                                    }

                                });
                                $.each(this.banner, function () {
                                    //console.log($size);
                                    if ($lang === 'ja') {
                                        $alt = this.alt;
                                        $img = this.img;
                                        if ('imgSP' in this) {
                                            $imgSP = this.imgSP;
                                        }
                                        $link = this.link;
                                        $class = this.class;

                                    }
                                    if ($size === 'L') {
                                        prBnrL_Str += '<a href="' + $link + '" target="_blank" rel="noopener"><img src="' + $img + '" alt="' + $alt + '" width="780" height="584"></a>';
                                    }
                                    if ($size === 'M') {
                                        prBnrM_Str += '<a href="' + $link + '" target="_blank" rel="noopener"><picture class="section__mainpic"><source media="(min-width: 768px)" srcset="' + $img + '" ><img src="' + $imgSP + '" alt="' + $alt + '" width="391" height="194"></picture></a>';
                                    }
                                    if ($size === 'M-harf') {
                                        prBnrMharf_Str += '<a href="' + $link + '" target="_blank" rel="noopener" class="' + $class + '"><picture class="section__mainpic"><source media="(min-width: 768px)" srcset="' + $img + '"><img src="' + $imgSP + '" alt="' + $alt + '"></picture></a>';
                                    }
                                    if ($size === 'S') {
                                        prBnrS_Str += '<a href="' + $link + '" target="_blank" rel="noopener"><img src="' + $img + '" alt="' + $alt + '" width="488" height="97"></a>';
                                    }
                                    if ($size === 'S40') {
                                        prBnrS40_Str += '<div class="-sizeS40 sponsor__item"><a href="' + $link + '" target="_blank" rel="noopener"><img src="' + $img + '" alt="' + $alt + '" width="391" height="76"></a></div>';

                                    }
                                    if ($size === 'SS') {
                                        prBnrSS_Str += '<div class="-sizeSS sponsor__item"><a href="' + $link + '" target="_blank" rel="noopener"><img src="' + $img + '" alt="' + $alt + '" width="310" height="76"></a></div>';

                                    }

                                });

                                sponsorStr += '</div>';
                            });
                            sponsorStr += '</section>';
                        }
                    });
                    columnStr += '</div>';
                    $('#loadOutlineColumn').html(columnStr);
                    $('#loadSponsors').html(sponsorStr);
                    $('#sizeL').html(prBnrL_Str);
                    $('#sizeM').html(prBnrM_Str);
                    $('#sizeM-harf').html(prBnrMharf_Str);
                    $('#sizeS').html(prBnrS_Str);
                    $('#sizeS40').html(prBnrS40_Str);
                    $('#sizeSS').html(prBnrSS_Str);
                    Ts.loadFont();
                }
            },
            function () {
                $('#loadOutlineColumn').html(errorTxt);
            }
        )
}());