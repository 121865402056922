import intersectionObserver from './component/intersection-observer';
import splash from './component/splash';
import smoothscroll from './component/smoothscroll';
import archiveLinks from './component/archiveLinks';
import navigation from './component/navigation';
import whatsnew from './component/whatsnew';
import artists from './component/artists';
import slider from './component/slider';
import outline from './component/outline';
import gallery from './component/gallery';
import streaming from './component/streaming';
import lazyimageload from './component/lazyimageload';
