(function () {
    var artistsStr = '',
        $update,
        $name,
        $aID,
        $modalLink,
        $class = '',
        $lang = $('html').attr('lang'),
        newKeep = 5,
        y, m, d, diff, old_day, new_day,
        jsonID = '/2023/assets/data/artists.json',
        errorTxt = '<p class="error">読み込みエラーです。<span class="ib">ブラウザの再読み込みをお試しください。</span></p>';

    var sortBy = function (field, reverse, primer) {
        reverse = (reverse) ? -1 : 1;
        return function (a, b) {
            a = a[field];
            b = b[field];
            if (typeof (primer) !== 'undefined') {
                a = primer(a);
                b = primer(b);
            }
            if (a < b) {
                return reverse * -1;
            }
            if (a > b) {
                return reverse * 1;
            }
            return 0;
        };
    };



    $.when(
        $.ajax({
            url: jsonID,
            dataType: 'json'
        })
    )
        .then(

            function (data) {
                if ($('body.toppage')[0]) {
                    // if ($lang === 'ja') {
                    //     data.sort(sortBy('orderJa', false, String));
                    // } 

                    $.each(data, function () {
                        if (this.aID !== '') {

                            $update = this.update;

                            if (this.aID === 'comingsoon' || this.aID === '') {
                                $modalLink = 'javascript:void(0);';
                                $class = 'nolink';
                            } else {

                                $modalLink = '#modaalDialog';
                                $class = 'modaal';
                                if ($update !== '') {
                                    artistsStr += '<div class="artists__item ' + this.size + '"><a href="' + $modalLink + '" class="' + $class + '" data-id="' + this.aID + '">';
                                }
                                if (this.alt !== 'Coming Soon') {
                                    if ($lang === 'ja') {
                                        artistsStr += '<p class="artists__name"><span class="nameJa">' + this.nameJa + this.part + '</span></p>';
                                    }

                                }
                                artistsStr += '<figure class="artists__fig"><div class="artists__figinner"><img data-src="' + this.thumb + '" src="/2023/assets/images/artists/artist_lazy.jpg" alt="' + this.alt + '" class="lazy" width="320" height="320"></div></figure></a></div>';


                            }


                        }

                    });
                    $('#loadArtists').html(artistsStr);

                    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

                    if ("IntersectionObserver" in window) {
                        let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
                            entries.forEach(function (entry) {
                                if (entry.isIntersecting) {
                                    let lazyImage = entry.target;
                                    lazyImage.src = lazyImage.dataset.src;
                                    if (typeof lazyImage.dataset.srcset === "undefined") {
                                    } else {
                                        lazyImage.srcset = lazyImage.dataset.srcset;
                                    }
                                    lazyImage.classList.remove("lazy");
                                    lazyImageObserver.unobserve(lazyImage);
                                }
                            });
                        });

                        lazyImages.forEach(function (lazyImage) {
                            lazyImageObserver.observe(lazyImage);
                        });
                    } else {
                        // Possibly fall back to a more compatible method here
                    }

                    Ts.loadFont();

                    //モーダルウインドウ
                    $('.modaal').on('click', function () {
                        modalStr = '';
                        $aID = $(this).data('id');
                        $.each(data, function () {
                            if ($aID === this.aID) {
                                if ($lang === 'ja') {
                                    $name = this.nameJa;
                                    if (this.nameEn !== '') {
                                        $name += '<span class="small lang="en">　（' + this.nameEn + '）</span>';
                                    }
                                }
                                modalStr += '<div class="modal__cont">' +
                                    '<figure class="modal__fig"><img src="' + this.thumb + '" class="" alt=""></figure>' +
                                    '<div class="modal__data"><h3>' + $name + '</h3>';
                                if ($lang === 'ja') {
                                    modalStr += '<p class="description">' + this.modalCont[0].description + '</p>';
                                }
                                if (this.modalCont[0].message !== '') {
                                    modalStr += '<div class="message"><p>' + this.modalCont[0].message + '</p></div>';
                                }

                                modalStr += '<div class="modal__sns">';
                                $.each(this.modalCont[0].sns, function () {
                                    if (this.link !== '') {
                                        modalStr += '<div class="' + this.type + '"><a href="' + this.link + '" target="_blank" rel="noopener"><img src="/2023/assets/images/icon_' + this.type + '.png" alt="' + this.label + '">' + this.label + '</a></div>';
                                    } else {
                                        modalStr += '<div class="' + this.type + '"><img src="/2023/assets/images/icon_' + this.type + '.png" alt="' + this.label + '" class="nolink"></div>';
                                    }

                                });
                                modalStr += ''
                                modalStr += '</div></div>';
                            }
                        });

                        $('#loadModal').html(modalStr);

                        // $('.globalHeader__fix').hide();

                    }).modaal({
                        type: 'inline',
                        background_scroll: false,
                        accessible_title: '出演者の画像、名前、プロフィールなど。',
                        close_text: 'CLOSE',
                        close_aria_label: 'CLOSEボタンをクリックするとモーダルウインドウを閉じて元の画面に戻ります',
                        after_close: function () {
                            // $('.globalHeader__fix').show();
                        }
                    });
                }
            },
            function () {
                $('#loadArtists').html(errorTxt);
            }
        )
}());