//logoの表示
$(window).on('load', function () {

    if (!($('body.teaserTop')[0])) {
        var bp = 1080,
            headerH_narrow = 80,
            headerH_wide = 76;

        // 幅が768px以上のビューポートをターゲットとするメディアクエリを作成
        var mediaQuery = window.matchMedia('(min-width: 1080px)');

        function handleTabletChange(e) {
            posAdjust = $('.globalHeader').height();
            //console.log(posAdjust);
            // メディアクエリがtrueかどうかを確認
            if (e.matches) {
                $('#main').css({
                    "padding-top": "76px"
                });
            } else {
                $('#main').css({
                    "padding-top": posAdjust + "px"
                });
            }

        }
        // イベントリスナーを登録
        mediaQuery.addListener(handleTabletChange)

        // 初期チェック
        handleTabletChange(mediaQuery)

    }

    if ($('.toppage')[0]) {
        if ($('.splash')[0]) {
            $(".splash").delay(0).fadeOut('slow');//ローディング画面を1秒（500ms）待機してからフェードアウト
            $(".splash__logo").delay(0).fadeOut('slow');//ロゴを1秒（500ms）待機してからフェードアウト
            // $("#youtube-area").addClass('appear');
        }

    }



});

