(function () {
    var newStr = '',
        singleStr = '',
        $lang = $('html').attr('lang'),
        $update,
        newKeep = 5,
        y, m, d, diff, old_day, new_day,
        url = window.location.href,
        filename,
        jsonID = '/2023/assets/data/whatsnew_' + $lang + '.json',
        errorTxt = '<p class="error">読み込みエラーです。<span class="ib">ブラウザの再読み込みをお試しください。</span></p>';

    $.when(
        $.ajax({
            url: jsonID,
            dataType: 'json'
        })
    )
        .then(
            function (data) {
                if ($('body.toppage')[0] || $('body#new')[0]) {
                    if ($('#loadWhatnew')[0]) {
                        $.each(data, function () {
                            if ($lang === 'ja') {
                                newStr += '<div class="whatsnew__item"><a href="/2023/whatsnew/' + this.pageID + '.html">';
                            }

                            $update = this.date
                            if ($update !== '') {

                                y = $update.split('/')[0];
                                m = $update.split('/')[1];
                                d = $update.split('/')[2];
                                old_day = new Date(y + "/" + m + "/" + d);
                                new_day = new Date();
                                diff = (new_day - old_day) / (1000 * 24 * 3600);
                                if (diff <= newKeep) {
                                    newStr += '<p class="whatsnew__date -new">';
                                } else {
                                    newStr += '<p class="whatsnew__date">';
                                }
                            }
                            newStr += this.date + '</p><p class="whatsnew__text">' + this.title + '</p><figure class="whatsnew__fig"><img data-src="' + this.thumb + '" src="/2023/assets/images/thumb/thumb_lazy.jpg" alt="' + this.alt + '" class="lazy" width="600" height="315"></figure></a></div>';
                        });
                        $('#loadWhatnew').html(newStr);
                    }
                }

                if ($('body#new')[0] && $('body.newSingle')[0]) {
                    filename = url.match(".+/(.+?)\.[a-z]+([\?#;].*)?$")[1];
                    $.each(data, function () {
                        if (this.pageID === filename) {
                            singleStr += '<h3 class="newCont__ttl">' + this.title + '</h3><p class="newCont__update">' + this.date + '</p><figure class="newCont__fig"><img src="' + this.articlethumb + '" alt="' + this.articlealt + '" width="600" height="315"></figure>';
                            if (this.bodyText.length > 0) {
                                singleStr += '<div class="newCont__description">';
                                $.each(this.bodyText, function () {
                                    if (this.type === 'text') {
                                        $.each(this.text, function () {
                                            singleStr += '<p>' + this + '</p>';
                                        });
                                    } else if (this.type === 'headline') {
                                        $.each(this.text, function () {
                                            singleStr += '<h4>' + this + '</h4>';
                                        });
                                    } else if (this.type === 'column') {

                                        singleStr += '<div class="newCont__column">';
                                        if (this.subTitle !== '') {
                                            singleStr += '<h4>' + this.subTitle + '</h4>';
                                        }
                                        $.each(this.text, function () {
                                            singleStr += '<p>' + this + '</p>';
                                        });
                                        singleStr += '</div>';
                                    } else if (this.type === 'video') {
                                        singleStr += '<div class="newCont__video">';
                                        $.each(this.item, function () {
                                            singleStr += '<div class="newCont__videoitem"><a href="https://www.youtube.com/embed/' + this.videoID + '" class="modal"><figure class=""><div class="figInner"><img src="' + this.thumb + '" alt="' + this.alt + '" width="600" height="315"></div><figcaption>' + this.caption + '</figcaption></figure></a></div>';
                                        });
                                        singleStr += '</div>';
                                    } else if (this.type === 'profile') {
                                        singleStr += '<div class="newCont__profile"><h4>' + this.subTitle + '</h4>';
                                        if (this.katagaki !== '') {
                                            singleStr += '<p class="newCont__katagaki">' + this.katagaki + '</p>';
                                        }
                                        singleStr += '<figure class="newCont__memberpic"><img src="' + this.thumb + '" alt="' + this.subTitle + 'の画像" width="640" height="640"></figure>';
                                        $.each(this.text, function () {
                                            singleStr += '<p class="newCont__txt">' + this + '</p>';
                                        });
                                        singleStr += '</div>';

                                    } else if (this.type === 'setlist') {
                                        singleStr += '<div class="newCont__setlist"><p class="period">' + this.subTitle + '</p><div class="artist-songttl">';
                                        $.each(this.text, function (cnt) {
                                            singleStr += '<p class="newCont__txt row' + cnt + '">' + this + '</p>';
                                        });
                                        singleStr += '</div></div>';
                                    } else if (this.type === 'setlist1128') {
                                        singleStr += '<div class="newCont__setlist1128">';
                                        $.each(this.text, function (cnt) {
                                            singleStr += '<div class="newCont__1128text"><p class="programName">' + this.programName + '</p>';
                                            singleStr += '<p class="songName">' + this.songName + '</p>';
                                            singleStr += '<p class="year">（' + this.year + '）</p></div>';
                                        });

                                        singleStr += '</div>';
                                    } else if (this.type === 'list') {
                                        if (this.subTitle !== '') {
                                            singleStr += '<h4>' + this.subTitle + '</h4>';
                                        }
                                        singleStr += '<ul class="newCont__list">';
                                        $.each(this.text, function () {
                                            singleStr += '<li>' + this + '</li>';
                                        });
                                        singleStr += '</ul>';
                                    }
                                });
                                singleStr += '</div>';
                            }

                        }
                    });
                    $('#loadPage').html(singleStr);

                    $('.modal').modaal({
                        type: 'video'
                    });
                }


                if ($('body.toppage')[0]) {
                    $('#loadWhatnew').slick({
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        loop: false,
                        autoplay: false,
                        responsive: [
                            {
                                breakpoint: 992,
                                settings: {
                                    arrows: false,
                                    slidesToShow: 2
                                }
                            }
                        ]
                    });
                }

                Ts.loadFont();

                var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

                if ("IntersectionObserver" in window) {
                    let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
                        entries.forEach(function (entry) {
                            if (entry.isIntersecting) {
                                let lazyImage = entry.target;
                                lazyImage.src = lazyImage.dataset.src;
                                if (typeof lazyImage.dataset.srcset === "undefined") {
                                } else {
                                    lazyImage.srcset = lazyImage.dataset.srcset;
                                }
                                lazyImage.classList.remove("lazy");
                                lazyImageObserver.unobserve(lazyImage);
                            }
                        });
                    });

                    lazyImages.forEach(function (lazyImage) {
                        lazyImageObserver.observe(lazyImage);
                    });
                } else {
                    // Possibly fall back to a more compatible method here
                }
            },
            function () {
                $('#loadWhatnew').html(errorTxt);
            }
        )
}());
